import { Anchor, Badge, Tooltip } from "@mantine/core";
import { IconFile } from "@tabler/icons-react";

const getPublicFilesColumn = (handleDownloadAzure) => [
  {
    header: "Name",
    accessorKey: "name",
  },
  {
    header: "Description",
    accessorKey: "description",
    Cell: ({ cell }) => {
      // Get the full description and check if it is "undefined" (as a string)
      let fullText = cell.getValue() || "";
      if (fullText === "undefined") {
        fullText = "";
      }
      // Truncate the description to 30 characters (you can adjust this value)
      const truncatedText =
        fullText.length > 30 ? fullText.substring(0, 30) + "..." : fullText;

      return (
        <Tooltip
          label={fullText}
          withArrow
          // Use the styles prop for custom tooltip styling
          styles={(theme) => ({
            tooltip: {
              maxWidth: 300,          // Limit maximum width (px)
              whiteSpace: "normal",   // Allow the text to wrap
              wordBreak: "break-word" // Break long words if necessary
            },
          })}
        >
          <div>{truncatedText}</div>
        </Tooltip>
      );
    },
  },
  {
    header: "File Download",
    accessorKey: "filepath",
    accessorFn: (row) =>
      row?.filepath && row.filepath !== "" && row.filepath !== "null" ? (
        <Anchor target="_blank" onClick={() => handleDownloadAzure(row.filepath)}>
          <IconFile />
        </Anchor>
      ) : null,
  } ,
  {
    header: "Account Status",
    accessorKey: "status",
    Cell: ({ cell }) => (
      <Badge color={cell.getValue() ? "green" : "red"}>
        {cell.getValue() ? "Active" : "InActive"}
      </Badge>
    ),
  },
];

export default getPublicFilesColumn;
