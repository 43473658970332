import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mantine/core";
import { MantineReactTable } from "mantine-react-table";
import { useSelector } from "react-redux";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";
import axios from "axios";

const EncounterCumalativeData = () => {
  const [rowData, setRowData] = useState([]);
  const navigate = useNavigate();
  const [loading, setIsLoading] = useState(false);

  // Using redux to determine the tin (assuming clinic.tin holds the tin value)
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const selectedGroupId = useSelector((store) => store.partenerTree.selectedGroupId);
  const partenerTreeItems = useSelector((state) => state.partenerTree.partenerTreeItem);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    // Find the right clinic from redux store information
    const group = partenerTreeItems[0]?.group?.find((g) => g.id === Number(selectedGroupId));
    const clinic = group?.clinic?.find((c) => c.id === reportSelection.clinicId);
    if (clinic && clinic.tin) {
      getCSVdataList(clinic.tin);
    }
  }, [selectedGroupId, reportSelection.clinicId, reportSelection.selectedYear, partenerTreeItems]);

  const getCSVdataList = (tin) => {
    setIsLoading(true);
    // Hardcoded tin value as per your sample; you can replace with tin if needed.
    const url = `http://ec2-18-211-36-153.compute-1.amazonaws.com/api/tmp/error/list?tin=${'205678912'}`;
    axios.get(url, {
        
        headers: {
          "accept": "application/json",
          "token": "a0e5f6430d7563ad9249a8b00c7c882b",
        },
      })
      .then((response) => {
       
        setRowData(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setRowData([]);
        setIsLoading(false);
      });
  };

  // Define columns based on the new data structure including a serial number and extra columns
  const columnsDef = useMemo(
    () => [
      {
        header: "S.No",
        id: "serial", // no accessorKey because this is a computed column
        Cell: ({ row }) => row.index + 1,
      },
      {
        header: "File ID",
        accessorKey: "file_id",
      },
      {
        header: "Birthdate",
        accessorKey: "birthdate",
      },
      {
        header: "Encounter Date",
        accessorKey: "encounter_date",
      },
      {
        header: "Created At",
        accessorKey: "created_at",
      },
      {
        header: "NPI",
        accessorKey: "npi",
      },
      {
        header: "Patient UUID",
        accessorKey: "patient_uuid",
      },
      {
        header: "Reason",
        accessorKey: "reason",
      },
      {
        header: "TIN",
        accessorKey: "tin",
      },
      {
        header: "Gender",
        accessorKey: "gender",
      },
      {
        header: "ICD-10 Codes",
        accessorKey: "icd_10_codes",
      },
      {
        header: "Modifier",
        accessorKey: "modifier",
      },
    ],
    []
  );

  return (
    <Box style={{ marginTop: "15px" }}>
      <MantineReactTable
        columns={columnsDef}
        data={rowData}
        state={{ isLoading: loading }}
        mantinePaperProps={{ shadow: "0", withBorder: false }}
        initialState={{}}
      />
    </Box>
  );
};

export default EncounterCumalativeData;
