import React, { useState } from "react";
import { useForm } from "@mantine/form";
import { Button, Box, Grid, FileInput, LoadingOverlay, Anchor, Group, Text } from "@mantine/core";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import axios from "axios";
import { IconDownload } from "@tabler/icons-react";
import { useSelector } from "react-redux";

export const EncounterFileUpload = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const reportSelection = useSelector((store) => store.selectedClinic.item);

  const form = useForm({
    initialValues: {
      year: "2024", // preset year (or set via user input if desired)
      file: null,
    },
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("year", reportSelection.selectedYear);
    formData.append("file", values.file);

    try {
     
      await axios.post(
        "https://macramonitorfunctions.azurewebsites.net/api/EncounterRegistry?code=C2ObmCP7JjcW0ounc161UR1h5xpVtKFnFET3O2xEvw0hAzFuIwtbnw%3D%3D",
        formData,
        {
          headers: {
            accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setLoading(false);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Record Saved Successfully",
        timer: 1000,
        showConfirmButton: false,
      }).then(() => {
        navigate("/user/encounterData/encounterlist");
      });
    } catch (error) {
      console.error("Error uploading file:", error);
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to save record.",
      });
    }
  };

  return (
    <Box style={{ position: "relative" }}>
      <LoadingOverlay visible={loading} overlayOpacity={0.3} />
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Grid>
          <Grid.Col span={6}>
            <Anchor
              href="/Demo.csv"
              download
              style={{ marginBottom: "1rem", display: "inline-block" }}
            >
              <Group spacing="xs">
                <IconDownload size={16} />
                <Text size="sm">Encounter CSV Format</Text>
              </Group>
            </Anchor>

            <FileInput
              label="Upload CSV"
              placeholder="Choose CSV file"
              accept=".csv"
              {...form.getInputProps("file")}
            />
          </Grid.Col>
        </Grid>

        <Button type="submit" style={{ marginTop: "1rem" }}>
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default EncounterFileUpload;
