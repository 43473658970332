import React, { useState, useEffect, useMemo, useCallback } from "react";
import { RouteLink } from "../../Constant/RouterLink";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Switch } from "@mantine/core";
import { MantineReactTable } from "mantine-react-table";
import { useDisclosure } from "@mantine/hooks";
import { DeleteConfirmationModal } from "../../Shared/DeleteConfirmationModal";
import getPublicFilesColumn from "./PublicFilesColumn";
import { ApiLink } from "../../Constant/ApiLink";
import { useSelector } from "react-redux";
import { label } from "../../Shared/Constant";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";
import { RowAction } from "../../Shared/RowAction";

const PublicFilesList = () => {
  const [rowData, setRowData] = useState();
  const [loading, setIsLoading] = useState(false);
  const [deletingRow, setDeletingRow] = useState({});
  const [checked, setChecked] = useState(true);
  const navigate = useNavigate();
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const [opened, { open, close }] = useDisclosure(false);

  useEffect(() => {
    if (checked) {
      getPublicData(reportSelection.selectedYear);
    } else {
      getPublicData("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, reportSelection]);

  const getPublicData = (year) => {
    setIsLoading(true);
    ApiCall.axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}${ApiLink.PublicFiles}/${year}`
      )
      .then((response) => {
        setRowData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  };

  const onCreate = () => {
    navigate(RouteLink.PublicFile_Create_Uri, {
      state: { data: null, method: "post" },
    });
  };

  const onUpdate = (value) => {
    navigate(RouteLink.PublicFile_Update_Uri, {
      state: { data: value, method: "put" },
    });
  };

  const onDelete = (value) => {
    setDeletingRow(value);
    open();
  };

  const IsConfirmed = (value) => {
    if (value === true) {
      setIsLoading(true);
      ApiCall.axiosInstance
        .delete(
          `${process.env.REACT_APP_API_URL}${ApiLink.PublicFiles_Req}?id=${deletingRow.id}`
        )
        .then((response) => {
          setIsLoading(false);
          setRowData((oldValues) => {
            return oldValues.filter(
              (rowData) => rowData.id !== deletingRow.id
            );
          });
        })
        .catch((error) => {
          console.error("Failed Request:", error);
          setIsLoading(false);
        });
    }
  };

  // Wrap the function with useCallback to memoize it
  const handleDownloadAzure = useCallback(async (filepath) => {
    try {
      const fileName = filepath.split("/").pop();
      const apiUrl = `${process.env.REACT_APP_API_URL}${ApiLink.QualityDownload}/${fileName}?IA=Pu`;

      // Request the file blob from the API
      const response = await ApiCall.axiosInstance.get(apiUrl, {
        responseType: "blob",
      });

      // Create a temporary download link and click it programmatically
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("An error occurred during the download process:", error);
    }
  }, []); // dependencies array left empty if no external variables are used

  // Memoize the column definitions using the memoized download function
  const publicFilesColumnDefs = useMemo(
    () => getPublicFilesColumn(handleDownloadAzure),
    [handleDownloadAzure]
  );

  return (
    <Box style={{ marginTop: "15px" }}>
      <DeleteConfirmationModal
        IsConfirmed={IsConfirmed}
        opened={opened}
        open={open}
        close={close}
      />
      <Grid>
        <Grid.Col span={3}>
          <Button type="primary" onClick={onCreate}>
            Create Public Files
          </Button>
        </Grid.Col>
        <Grid.Col span={9}>
          <Switch
            style={{ float: "right" }}
            label={label("Show Yearly Data")}
            checked={checked}
            onChange={(event) => setChecked(event.currentTarget.checked)}
          />
        </Grid.Col>
      </Grid>
      <MantineReactTable
        columns={publicFilesColumnDefs}
        data={rowData ?? []}
        state={{ isLoading: loading }}
        enableRowActions
        positionActionsColumn="first"
        renderRowActions={(row) => (
          <RowAction
            onUpdate={() => onUpdate(row.row.original)}
            onDelete={() => onDelete(row.row.original)}
          />
        )}
        mantinePaperProps={{ shadow: "0", withBorder: false }}
      />
    </Box>
  );
};

export default PublicFilesList;
