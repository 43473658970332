import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Col, Select } from "@mantine/core";
import { MantineReactTable } from "mantine-react-table";
import { useSelector } from "react-redux";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";
import axios from "axios";

const EncounterRawData = () => {
  const [rowData, setRowData] = useState([]); // Default empty array for table data
  const [loading, setIsLoading] = useState(false);
  // State for the selected metric in the dropdown
  const [selectedMetric, setSelectedMetric] = useState("initial_population");

  // Retrieve values from redux
  const reportSelection = useSelector((store) => store.selectedClinic.item);
  const selectedGroupId = useSelector((store) => store.partenerTree.selectedGroupId);
  const partenerTreeItems = useSelector((state) => state.partenerTree.partenerTreeItem);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    // Find the right clinic from redux store information
    const group = partenerTreeItems[0]?.group?.find((g) => g.id === Number(selectedGroupId));
    const clinic = group?.clinic?.find((c) => c.id === reportSelection.clinicId);
    if (clinic && clinic.tin) {
      getDataList(clinic.tin, selectedMetric);
    }
  }, [selectedGroupId, reportSelection.clinicId, reportSelection.selectedYear, partenerTreeItems, selectedMetric]);

  const getDataList = (tin, metric) => {
    setIsLoading(true);
    // Build the API URL with required query parameters including metric
    const url = `http://ec2-18-211-36-153.compute-1.amazonaws.com/api/measures/filter/encounters?tin=${'205678912'}&entity_type=group&metric=${metric}&measure=047`;
    axios.get(url, {
        headers: {
          accept: "application/json",
          token: "a0e5f6430d7563ad9249a8b00c7c882b",
        },
      })
      .then((response) => {
        // Safely extract the encounters array; if not found, default to an empty array.
        const data = Array.isArray(response.data.encounters)
          ? response.data.encounters
          : [];
        setRowData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setRowData([]);
        setIsLoading(false);
      });
  };

  // Define table columns based on the expected response structure.
  const columnsDef = useMemo(
    () => [
      {
        header: "S.No",
        id: "serial",
        Cell: ({ row }) => row.index + 1,
      },
      {
        header: "Birthdate",
        accessorKey: "birthdate",
      },
      {
        header: "Code",
        accessorKey: "code",
      },
      {
        header: "Created At",
        accessorKey: "created_at",
      },
      {
        header: "Criteria",
        accessorKey: "criteria",
      },
      {
        header: "Encounter Date",
        accessorKey: "encounter_date",
      },
      {
        header: "ICD-10 Codes",
        accessorKey: "icd_10_codes",
      },
      {
        header: "Index",
        accessorKey: "index",
      },
      {
        header: "NPI",
        accessorKey: "npi",
      },
      {
        header: "Numerator CPT Code",
        accessorKey: "numerator_cpt_code",
      },
      {
        header: "Status",
        accessorKey: "status",
      },
      {
        header: "TIN",
        accessorKey: "tin",
      },
      {
        header: "Updated At",
        accessorKey: "updated_at",
      },
    ],
    []
  );

  return (
    <Box style={{ marginTop: "15px" }}>
      <Grid>
        {/* The dropdown is placed in a Grid column that spans 4 columns */}
        <Col span={4}>
          <Select
            label="Select Metric"
            placeholder="Select Metric"
            data={[
              { value: "initial_population", label: "initial_population" },
              { value: "non_eligible_population", label: "non_eligible_population" },
              { value: "eligible_population", label: "eligible_population" },
              { value: "performance_met", label: "performance_met" },
              { value: "performance_not_met", label: "performance_not_met" },
              { value: "datacompleteness_not_met", label: "datacompleteness_not_met" },
              { value: "denominator_exception", label: "denominator_exception" },
              { value: "denominator_exclusion", label: "denominator_exclusion" },
            ]}
            value={selectedMetric}
            onChange={setSelectedMetric}
            style={{ marginBottom: "15px" }}
          />
        </Col>
        {/* Optionally you can add other filters in additional columns */}
        <Col span={8}>{/* Additional grid columns or components can go here */}</Col>
      </Grid>

      <Box mt="md">
        <MantineReactTable
          columns={columnsDef}
          data={Array.isArray(rowData) ? rowData : []}
          state={{ isLoading: loading }}
          mantinePaperProps={{ shadow: "0", withBorder: false }}
          initialState={{}}
        />
      </Box>
    </Box>
  );
};

export default EncounterRawData;
