import { useForm } from "@mantine/form";
import React, { useState } from "react";
import {
  TextInput,
  Button,
  Box,
  Grid,
  Select,
  Space,
  FileInput,
  Loader,
  Anchor,
} from "@mantine/core";
import { ApiLink } from "../../Constant/ApiLink";
import { RouteLink } from "../../Constant/RouterLink";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import style from "../../Shared/Theme/CommanStyle.module.css";
import { ActiveStatus } from "../../Shared/DropDownOptions/ActiveStatus";
import { useSelector } from "react-redux";
import { ApiCall } from "../../Shared/ApiCall/ApiCall";

function PublicFilesForm() {
  // Define variables and state
  const navigate = useNavigate();
  const { data, method } = useLocation().state;
  const [loading, setLoading] = useState(false);
  const reportSelection = useSelector((store) => store.selectedClinic.item);

  // Set up the form with initial values and validation rules.
  // The custom validation below checks that at least one of name or status is provided.
  const form = useForm({
    initialValues: {
      id: data?.id,
      name: data?.name || "",
      description: data?.description || "",
      status: data?.status || "",
      filepath: data?.filepath || null,
      year: data?.year ? data?.year : reportSelection.selectedYear,
    },
    validate: (values) => {
      const errors = {};
      if (!values.name && !values.status) {
        const errorMsg = "Either Name or Status is required";
        errors.name = errorMsg;
        errors.status = errorMsg;
      }
      return errors;
    },
  });

  const handleSubmit = async (formValues) => {
    setLoading(true);

    // Create a FormData instance and append your form fields.
    const formData = new FormData();
    formData.append("id", formValues.id);
    formData.append("name", formValues.name);
    formData.append("description", formValues.description);
    formData.append("status", formValues.status);
    formData.append("year", formValues.year);
    formData.append("filepath", formValues.filepath);
    

    // Append the file if it exists. Ensure the key "File" matches your API expectation.
    if (formValues.filepath) {
      formData.append("File", formValues.filepath);
      formData.append("filepath", formValues.filepath.name);
    }
   

    try {
      ApiCall.axiosInstance[method](
        `${process.env.REACT_APP_API_URL}${ApiLink.PublicFiles_Req}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
        .then((response) => {
          setLoading(false);
          navigate(RouteLink.PublicFile_Uri);
        })
        .catch((error) => {
          console.error("Failed Request:", error);
          setLoading(false);
        });
    } catch (error) {
      console.error("Unexpected Error:", error);
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate(RouteLink.PublicFile_Uri);
    setLoading(false);
  };

  const customAnchoreTagStyle = {
    fontWeight: "500",
  };


  const handleDownloadAzure = async (filepath) => {
    try {
      const fileName = filepath.split("/").pop();
      const apiUrl = `${process.env.REACT_APP_API_URL}${ApiLink.QualityDownload}/${fileName}?IA=Pu`;
  
      // Await the API call to get the response blob
      const response = await ApiCall.axiosInstance.get(apiUrl, {
        responseType: "blob",
      });
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("An error occurred during the download process:", error);
    }
  };
  

  return (
    <Box className={style.mt2}>
      {loading && <Loader size={48} />}
      <h5>Public Files: Add New</h5>
      <Space />

      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Grid>
          <Grid.Col span={4}>
            <TextInput
              label="Name"
              withAsterisk
              {...form.getInputProps("name")}
            />
          </Grid.Col>
          <Grid.Col span={8}>
            <TextInput
              label="Descriptions"
              withAsterisk
              {...form.getInputProps("description")}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Select
              label="Status:"
              defaultValue={data?.account}
              withAsterisk
              {...form.getInputProps("status")}
              data={ActiveStatus}
            />
          </Grid.Col>
          <Grid.Col span={8}>
          <label>Upload File</label> &nbsp;
          {(form.values.filepath !== null && form.values.filepath !== "null") && (
  <Anchor onClick={() => handleDownloadAzure(form.values.filepath)}>
    <span style={customAnchoreTagStyle}>Download</span>
  </Anchor>
)}

  <FileInput
    placeholder="Pick file"
    {...form.getInputProps("filepath")}
  />
</Grid.Col>

        </Grid>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          <Button type="button" onClick={handleBack}>
            Back
          </Button>
          <Button style={{ marginLeft: "10px" }} type="submit">
            Submit
          </Button>
        </div>
      </form>
    </Box>
  );
}

export default PublicFilesForm;
